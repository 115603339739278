<template>
  <v-card class="w-100 d-flex pa-6" elevation="4">
    <v-text-field
      outlined
      :placeholder="$t('form.placeholder.search')"
      :value="item.searchTerm"
      @input="debounceSearchInput"
      prepend-inner-icon=""
      hide-details=""
      full-width
      ref="input"
      class="full-width-input w-100 v-input--active primary--text focus-input"
    >
      <template v-slot:prepend-inner>
        <v-icon class="" size="20">$search</v-icon>
      </template>
    </v-text-field>

    <div class="d-flex flex-wrap align-center justify-start mt-2">
      <span class="gray--text text--lighten-1 p-5 mr-2 mt-2"
        >{{ $t('heading.instance.plugin.add.tags') }}</span
      >
      <v-chip
        small
        class="mr-2 mt-2"
        @click="$emit('update:searchTerm', item)"
        color="gray lighten-4"
        v-for="(item, index) in popularTags"
        :key="`${item}-${index}`"
      >
        <span class="gray--text text--darken-2">{{ item }}</span>
      </v-chip>
    </div>
  </v-card>
</template>

<script>
import * as _ from "lodash";

export default {
  props: {
    isActive: Boolean,
    item: Object,
  },
  data() {
    return {
      popularTags: [
        this.$t('heading.instance.plugin.add.tag.blog'),
        this.$t('heading.instance.plugin.add.tag.ecommerce'),
        this.$t('heading.instance.plugin.add.tag.entertainment'),
        this.$t('heading.instance.plugin.add.tag.news'),
        this.$t('heading.instance.plugin.add.tag.portfolio'),
      ],
    };
  },
  methods: {
    debounceSearchInput: _.debounce(function (value) {
      this.$emit("update:searchTerm", value);
    }, 300),
    focusOnInput: function () {
      setTimeout(() => {
        const input =  document.querySelector(".focus-input input")
        input.focus();
      }, 200);
    },
  },
  watch: {
    isActive: function (value) {
      if (value) {
        this.focusOnInput();
      }
    },
  },
  created() {
    this.focusOnInput();
  },
};
</script>
<style lang="scss" scoped>
.v-chip.v-size--small {
  font-size: 10px;
  font-weight: $font-weight-bold;
  padding: 4px;
  border-radius: 5px;
}
</style>
