<template>
  <v-container class="px-0 pt-0">
    <v-card elevation="4" class="data-table">
      <v-data-table
        calculate-widths
        :headers="headers"
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :sort-by.sync="sortByComputed.value"
        :sort-desc.sync="sortDesc"
        hide-default-footer
        hide-default-header
        class="elevation-0 custom-data-table"
        :loading="itemsLoading"
      >
        <template v-slot:header="{ isMobile, props, on }">
          <table-custom-sort
            :isMobile="isMobile"
            :props="props"
            :v-on="on"
            :canBeSelected="false"
            :allSelected="allSelected"
            @selectAll="handleSelectAll"
            v-on="$listeners"
          />
        </template>

        <template v-slot:item.name="{ item, index }">
          <div
            class="item-bordered"
            :class="[item.featured ? 'item-bordered--yellow' : '']"
          >
            <div class="d-flex flex-column text-truncate">
              <span v-if="item.featured" class="d-flex align-center">
                <span
                  class="font-weight-bold text-truncate d-block flex-start"
                  style="max-width: 415px"
                  v-html="item.name"
                />

                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      color="gray lighten-1"
                      size="16"
                      class="ml-2 primary-on-hover"
                    >
                      $alertcircle
                    </v-icon>
                  </template>
                  <span class="base-text">{{ $t('tooltip.instance.plugin.add.special') }}</span>
                </v-tooltip>
                <div class="featured-chip ml-2">{{ $t('general.featured') }}</div>
              </span>

              <span
                v-else
                class="font-weight-bold text-truncate"
                style="max-width: 450px"
                v-html="item.name"
              >
              </span>

              <div class="d-flex">
                <div
                  class="text-truncate"
                  v-html="getDescription(item.short_description || item.description)"
                  :ref="`text-${index}`"
                  style="max-width: 450px"
                />
                <a
                  v-if="item.description.length > 100"
                  @click="$emit('action-button-read-more', item)"
                  class="info--text font-weight-bold ml-1"
                >{{ $t('button.readMore') }}</a>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex actions-row">
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  style="min-width: 38px; max-width: 38px"
                  class="ml-auto"
                  color="primary"
                  @click.native="$emit('action-button-install', item)"
                  small
                  elevation="0"
                >
                  <v-icon
                    v-if="
                      !loading &&
                      !item.hasInstallInProgress() &&
                      !item.hasUpdateInProgress()
                    "
                    size="18"
                    class="mr-0"
                    color="white"
                  >
                    $installIcon
                  </v-icon>

                  <loader
                    v-else
                    color="white"
                    size="24"
                    class="mr-0"
                    :noMargins="true"
                  />
                </v-btn>
              </template>

              <span class="base--text">{{ $t('button.plugin.install') }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.version="{ item }">
          <div class="d-flex align-center">
            <v-icon
              v-if="item.update !== 'available'"
              color="primary"
              size="16"
            >
              $alertok
            </v-icon>
            <v-icon v-else color="error" size="16">$alertwarning</v-icon>

            <span class="ml-2">{{ item.version }}</span>
          </div>
        </template>

        <template v-slot:item.rating="{ item }">
          <div class="d-flex align-center p-4">
            <v-icon size="20" class="mr-1" :percent="item.rating / 100"
              >$starIcon</v-icon
            >
            <b class="">{{ Math.ceil((item.rating / 10) * 5) / 10 }}</b>
          </div>
        </template>

        <template v-slot:item.active_installs="{ item }">
          <div elevation="0" class="d-flex align-center p-4" v-if="item.active_installs">
            <v-icon size="20" class="mr-1" color="gray lighten-1"
              >$installIcon</v-icon
            >
            <b class="">{{ item.active_installs }}+</b>
          </div>
        </template>

        <template v-slot:item.tested="{ item }">
          <div class="d-flex align-center" v-if="item.tested">
            <v-icon size="20" class="mr-1" color="gray lighten-1"
              >$installIcon</v-icon
            >
            {{ item.tested }}
          </div>
        </template>

        <template v-slot:no-data>
          <data-iterator-no-results-container
            v-if="searchTerm"
            @clearAllFilters="$emit('clearAllFilters')"
            :searchTerm="searchTerm"
          />
        </template>

        <template v-slot:no-results>
          <data-iterator-no-results-container
            @clearAllFilters="$emit('clearAllFilters')"
            :searchTerm="searchTerm"
          />
        </template>

        <template v-slot:footer>
          <div>
            <data-iterator-footer
              :page="page"
              :numberOfPages="numberOfPages"
              :possibleItemsPerPage="possibleItemsPerPage"
              :listStyle="listStyle"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              v-on="$listeners"
              :perPageLabel="$t('table.footer.plugin')"
            >
            </data-iterator-footer>
          </div>
        </template>
        <template v-if="itemsLoading" v-slot:body>
          <NewPluginTableItemsSkeleton :rowsNumber="itemsPerPage" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../mixins/CustomTableMixin";

import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";
import TableCustomSort from "../dataIterator/TableCustomSort.vue";
import NewPluginTableItemsSkeleton from "./NewPluginTableItemsSkeleton.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    DataIteratorNoResultsContainer,
    NewPluginTableItemsSkeleton,
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    sortBy: [String, Object],
    searchTerm: String,
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  computed: {
    sortByComputed: {
      get: function () {
        return this.sortBy;
      },
      set: function (value) {
        this.$emit("update:sortBy", value);
      },
    },
  },
  methods: {
    getPluginUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "-");
    },
    getDescription(item) {
      let currentLength = 100;

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card.data-table {
  border-radius: 8px !important;
}
.text-truncate {
  text-overflow: ellipsis " " !important;
}
.v-data-table.custom-data-table::v-deep {
  .v-data-table__wrapper > table > tbody > tr > td {
    height: 64px;
  }
}

.item-bordered::v-deep {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -24px;
    top: -14px;
    height: calc(100% + 24px);
    width: 4px;
    background-color: transparent;
  }

  &.item-bordered--yellow {
    &::after {
      background-color: #fadd77 !important;
    }
  }
  .primary-on-hover:hover {
    color: var(--v-primary-base) !important;
    transition: color 0.24s ease;

    .v-icon svg,
    svg {
      color: var(--v-primary-base) !important;
      caret-color: var(--v-primary-base) !important;
    }
  }
}

.featured-chip {
  padding: 4px;
  border-radius: 5px;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  font-weight: $font-weight-bold;
  background-color: #fff2d3;
  color: #ec7b13;
  display: inline-block;
}
</style>
