const CustomTableMixin = {
    props: {
        checkedItems: {
            type: Array,
        },
    },
    data: function () {
        return {
            canBeSelected: true
        }
    },
    computed: {
        allSelected: function () {
            return this.checkedItems.length === this.items.length && this.items.length !== 0;
        },
    },
    methods: {
        handleSelectAll(data) {
            this.$emit("update:check-all", data);
        },
        handleSelectItem(item) {
            this.$emit("update:checked-items", item);
        },
        changeSortBy(data) {
            this.$emit("update:sort-by", data);
        },
        changeSortDesc(data) {
            this.$emit("update:sort-desc", data);
        },
    }
}

export default CustomTableMixin