import Api from "../apis/Api"
import ActionModalMixin from "./ActionModalMixin"

const PluginsActionsMixin = {
    mixins: [ActionModalMixin],
    data: function () {
        return {
            allItems: [],
            applicationId: this.$route.params.subinstanceId,
        }
    },
    methods: {
        //INSTALL MODALS

        showPluginInstallModal(item) {
            this.resetModal();
            this.modalOptions.icon = "$restorebackup";
            this.modalOptions.title = this.$t('heading.instance.plugin.install.title');
            this.modalOptions.message = `${item.short_description}`

            this.modalOptions.buttons.unshift({
                label: this.$t('button.install'),
                color: "primary",
                onclick: () => {
                    this.installPlugin(item);
                },
            });
            this.modalOptions.item = item;
            this.modalOptions.open = true;
        },
        installPlugin(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.post(
                `/instances/${this.applicationId}/wordpress/plugins/new/${item.slug}/install`
            )
                .then(() => {
                    item.async_status = { install: "pending" };
                    this.$root.$emit("plugin-item-changed", { ...item });
                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.$t('notification.instance.plugin.install.success'),
                    });
                    this.modalClose();
                    this.loadData();
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },

        //ACTIVATE MODALS

        showPluginActivateModal(item) {
            this.resetModal();

            this.modalOptions.title = this.$t('heading.instance.plugin.activate.title', {count: 1});
            this.modalOptions.message = this.$t('heading.instance.plugin.activate.info', {count: 1});
            this.modalOptions.icon = "$activate";
            this.modalOptions.buttons.unshift({
                label: this.$t('button.activate'),
                color: "primary",
                onclick: () => {
                    this.activatePlugin(item);
                },
            });
            this.modalOptions.open = true;
        },
        showPluginMassActivateModal(items) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.activate.title', {count: items.length});
            this.modalOptions.message = this.$t('heading.instance.plugin.activate.info', {count: items.length});
            this.modalOptions.icon = "$activate";
            this.modalOptions.buttons.unshift({
                label: this.$t('button.activate'),
                color: "primary",
                onclick: () => {
                    this.massActivatePlugin(items)
                },
            });
            this.modalOptions.open = true;
        },
        async massActivatePlugin(items) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            let index = 0

            var errors = []

            const activate = async (i) => {
                try {
                    await Api.put(
                        `/instances/${this.applicationId}/wordpress/plugins/${items[i].name}/activate`
                    )

                    index++
                } catch (error) {
                    errors.push(Api.getErrorMessage(error));

                    // this.$store.dispatch("addAlert", {
                    //     success: false,
                    //     errorMessage: this.modalOptions.submittingError,
                    // });

                    index++
                }
            }

            while (index < items.length) {
                await activate(index)
            }

            if (!errors.length) {
                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.$t('notification.instance.plugin.activate.success', {count: items.length}),
                });
            } else {
                const uniqueErrors = [...new Set(errors)]

                uniqueErrors.map(error => {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: error,
                    });
                })
            }


            this.modalClose();
            this.loadData();

            this.modalOptions.open = false;
            this.modalOptions.persistent = false;
            this.modalOptions.submitting = false;
        },
        activatePlugin(item) {

            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.put(
                `/instances/${this.applicationId}/wordpress/plugins/${item.name}/activate`
            )
                .then(() => {
                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.$t('notification.instance.plugin.activate.success', {count: 1}),
                    });
                    this.modalClose();
                    this.loadData();
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },

        // DEACTIVATE MODALS

        showPluginDeactivateModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.deactivate.title', {count: 1});
            this.modalOptions.message = this.$t('heading.instance.plugin.deactivate.message', {count: 1});
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$alertwarning";

            this.modalOptions.buttons.unshift({
                label: this.$t('button.deactivate'),
                color: "error",
                onclick: () => {
                    this.deactivatePlugin(item);
                },
            });
            this.modalOptions.open = true;
        },
        showPluginMassDeactivateModal(items) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.deactivate.title', {count: items.length});
            this.modalOptions.message = this.$t('heading.instance.plugin.deactivate.message', {count: items.length});
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$disable";
            this.modalOptions.item = items

            this.modalOptions.buttons.unshift({
                label: this.$t('button.deactivate'),
                color: "error",
                onclick: () => {
                    this.massDeactivatePlugin(items);
                },
            });
            this.modalOptions.open = true;
        },
        async massDeactivatePlugin(items) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            let index = 0
            var errors = []

            const activate = async (i) => {
                try {
                    await Api.put(
                        `/instances/${this.applicationId}/wordpress/plugins/${items[i].name}/deactivate`
                    )

                    index++
                } catch (error) {
                    errors.push(Api.getErrorMessage(error));

                    // this.$store.dispatch("addAlert", {
                    //     success: false,
                    //     errorMessage: this.modalOptions.submittingError,
                    // });

                    index++
                }
            }

            while (index < items.length) {
                await activate(index)
            }

            if (!errors.length) {
                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.$t('notification.instance.plugin.deactivate.success', {count: items.length}),
                });
            } else {
                const uniqueErrors = [...new Set(errors)]

                uniqueErrors.map(error => {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: error,
                    });
                })
            }

            this.modalClose();
            this.loadData();
        },
        deactivatePlugin(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";
            Api.put(
                `/instances/${this.applicationId}/wordpress/plugins/${item.name}/deactivate`
            )
                .then(() => {
                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.$t('notification.instance.plugin.deactivate.success', {count: 1}),
                    });
                    this.modalClose();
                    this.loadData();
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },

        // UPDATE MODALS

        showPluginUpdateModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.update.title', {count: 1});
            this.modalOptions.message = this.$t('heading.instance.plugin.update.message_one', {count: 1, version: item.update_version});
            this.modalOptions.icon = "$update";
            this.modalOptions.buttons.unshift({
                label: this.$t('button.update'),
                color: "primary",
                onclick: () => {
                    this.updatePlugin(item);
                },
            });
            this.modalOptions.open = true;
        },
        showPluginMassUpdateModal(items) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.update.title', {count: items.length})
            let message = "";
            this.modalOptions.item = items.filter(item => item.update == 'available')
            this.checkedItems.map((item) => {
                const foundItem = this.allItems.find((i) => i.name === item && i.update == 'available');
                if(!foundItem) return
                if (!foundItem?.update_version) {
                    return "";
                }
                message =
                    message +
                    `<li>${this.$t('heading.instance.plugin.update.message.content', {
                        plugin: `<b class="capitalize">${foundItem.name}</b>`,
                        version: `<b>${foundItem.version}</b>`,
                        updateVersion: `<b>${foundItem.update_version}</b>`
                    })}</li>`;
            });

            this.modalOptions.message = `
            ${this.$t('heading.instance.plugin.update.message.title', {count: items.length})}
            <ul style="${message ? "margin-top: 24px" : "display: none"
                }">${message}</ul>`;

            this.modalOptions.icon = "$update";

            const itemsWithUpdate = items.filter(item => item.update == 'available').map(item => item.name)
            this.modalOptions.buttons.unshift({
                label: this.$t('button.update'),
                color: "primary",
                onclick: () => {
                    this.massUpdatePlugin(itemsWithUpdate);
                },
            });
            this.modalOptions.open = true;
        },
        updatePlugin(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.put(`/instances/${this.applicationId}/wordpress/plugins/${item.name}/update`)
                .then(() => {
                    item.async_status = { update: "pending" };
                    this.$root.$emit("plugin-item-changed", item);
                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.$t('notification.instance.plugin.update.success', {count: 1})
                    });
                    this.modalClose();
                    this.loadData();
                })
                .catch((error) => {
                    this.modalOptions.submittingError = Api.getErrorMessage(error);

                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: this.modalOptions.submittingError,
                    });
                })
                .finally(() => {
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
        massUpdatePlugin(items) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            const actions = items.map((item) => {
                return Api.put(`/instances/${this.applicationId}/wordpress/plugins/${item}/update`)
            })


            Promise.all(actions).then(() => {
                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.$t('notification.instance.plugin.update.success_other', {count: items.length})
                });
                this.modalClose();
                this.loadData();
            }).catch((error) => {
                this.modalOptions.submittingError = Api.getErrorMessage(error);

                this.$store.dispatch("addAlert", {
                    success: false,
                    errorMessage: this.modalOptions.submittingError,
                });
            }).finally(() => {

                this.modalOptions.open = false;
                this.modalOptions.persistent = false;
                this.modalOptions.submitting = false;
            })
        },

        // DELETE MODALS

        showPluginMassDeleteModal(items) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.delete.title', {count: items.length});
            this.modalOptions.message = this.$t('heading.instance.plugin.delete.message', {count: items.length});
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$alertwarning";
            this.modalOptions.item = items

            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.plugin', {count: items.length}),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];

            this.modalOptions.buttons.unshift({
                label: this.$t('button.delete'),
                color: "error",
                onclick: () => {
                    this.massDeletePlugin(this.checkedItems);
                },
            });
            this.modalOptions.open = true;
        },
        massDeletePlugin(items) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            let deleteSelected = items.map((item) => {
                return Api.delete(
                    `/instances/${this.applicationId}/wordpress/plugins/${item.name}`
                );
            });

            Promise.all(deleteSelected).then(() => {
                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.$t('notification.instance.plugin.delete.success', {count: items.length})
                });
                this.modalClose();
                this.loadData();
                this.checkedItems = []
            }).catch((error) => {
                this.modalOptions.submittingError = Api.getErrorMessage(error);
                this.$store.dispatch("addAlert", {
                    success: false,
                    errorMessage: this.modalOptions.submittingError,
                });
            }).finally(() => {
                this.modalOptions.open = false;
                this.modalOptions.persistent = false;
                this.modalOptions.submitting = false;
            });
        },
        showPluginDeleteModal(item) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.instance.plugin.delete.title', {count: 1});
            this.modalOptions.message = this.$t('heading.instance.plugin.delete.message', {count: 1});
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$alertwarning";
            this.modalOptions.item = item

            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.plugin', {count: 1}),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];

            this.modalOptions.buttons.unshift({
                label: this.$t('button.delete'),
                color: "error",
                onclick: () => {
                    this.deletePlugin(item);
                },
            });
            this.modalOptions.open = true;
        },
        deletePlugin(item) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.delete(`/instances/${this.applicationId}/wordpress/plugins/${item.name}`).then((response) => {
                this.$store.dispatch("addAlert", {
                    success: true,
                    successMessage: this.$t('notification.instance.plugin.delete.success', {count: 1})
                });

                this.modalClose();
                this.instance.setPluginsData(response.data.data);
                this.setData(response.data.data);
            }).catch((error) => {
                this.modalOptions.submittingError = Api.getErrorMessage(error);

                this.$store.dispatch("addAlert", {
                    success: false,
                    errorMessage: this.modalOptions.submittingError,
                });
            }).finally(() => {
                this.modalOptions.open = false;
                this.modalOptions.persistent = false;
                this.modalOptions.submitting = false;
            });
        },
    }
}

export default PluginsActionsMixin