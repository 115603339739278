<template>
  <div class="no-data py-12 px-12">
    <div class="no-data__img mb-6">
      <slot name="image" />
    </div>
    <div class="no-data__content" :style="`max-width: ${maxWidth};`">
      <h3 class="no-data__title text--heading">
        {{ title }}
      </h3>
      <p class="no-data__desc mt-4 mb-0 p-1 font-weight-light text-center gray--text text--darken-1">
        {{ desc }}
      </p>

      <div v-if="hasActions" class="no-data__action mt-6">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    maxWidth: {
      type: String,
      default: "560px",
    },
  },
  computed: {
    hasActions: function () {
      return this.$slots.action ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-weight: $font-weight-extrabold;
    text-align: center;
    // color: #000;
    line-height: 40px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    // color: var(--v-text-base);
    // color: #000;
  }
  &__action {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
