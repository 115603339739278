<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12">
        <v-card
          elevation="4"
          class="data-table"
          :class="canBeSelected ? 'data-table--selectable' : ''"
        >
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="itemsLoading ? [] : items"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :search="searchTermComputed"
            :sort-by="sortBy.value"
            :sort-desc="sortDesc"
            hide-default-footer
            hide-default-header
            class="elevation-0 custom-data-table"
            :server-items-length="itemsTotal"
          >
            <template v-if="itemsLoading" v-slot:body>
              <plugin-table-sort-skeleton/>
              <plugin-table-items-skeleton/>
            </template>

            <template v-slot:header="{ isMobile, props, on }">
              <table-custom-sort
                v-if="!itemsLoading"
                :isMobile="isMobile"
                :props="props"
                :v-on="on"
                :canBeSelected="true"
                :allSelected="allSelected"
                @selectAll="handleSelectAll"
                v-on="$listeners"
              />
            </template>

            <template v-slot:no-data>
              <data-iterator-no-results-container
                @clearAllFilters="clearFilters"
                :searchTerm="searchTermComputed"
              />
            </template>

            <template v-slot:no-results>
              <data-iterator-no-results-container
                @clearAllFilters="clearFilters"
                :searchTerm="searchTermComputed"
              />
            </template>

            <template v-slot:item.select="{ item }">
              <div
                class="item-bordered"
                :class="[
                  item.status === 'active' ? 'item-bordered--green' : '',
                  item.update === 'available' ? 'item-bordered--red' : '',
                ]"
              >
                <checkbox
                  @change="handleSelectItem(item)"
                  :checked="checkedItems.includes(item.name)"
                />
              </div>
            </template>

            <template v-slot:item.title="{ item }">
              <div class="d-flex flex-column">
                  <span class="font-weight-bold">{{ item.title }}</span>
                <div class="d-flex">
                  <div
                    class="text-truncate"
                    style="max-width: 450px"
                    v-html="getDescription(item.description)"
                  />
                  <a
                    v-if="item.description.length > 100"
                    @click="$emit('action-button-read-more', item)"
                    class="info--text font-weight-bold ml-1"
                  >
                    {{ $t('button.readMore') }}</a
                  >
                </div>
              </div>
            </template>

            <template v-slot:item.version="{ item }">
              <v-icon
                v-if="item.update !== 'available'"
                color="primary"
                size="16"
              >
                $alertok
              </v-icon>
              <v-icon v-else color="error" size="16">$alertwarning</v-icon>

              <span class="ml-2">{{ item.version }}</span>
            </template>

            <template v-slot:item.status="{ item }">
              <status-label
                :value="item.status == 'active' ? $t('message.status.activated') : $t('message.status.disabled')"
                :small="true"
                :status="item.status == 'active' ? 'primary' : 'gray'"
              ></status-label>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-flex actions-row">
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                  v-if="item.status == 'inactive'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      v-on="on"
                      v-bind="attrs"
                      icon
                      @click="$emit('action-button-activate', item)"
                    >
                      <v-icon>$checkcircle</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.plugin.activate') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                  v-else
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      icon
                      @click="$emit('action-button-deactivate', item)"
                    >
                      <v-icon>$xcircle</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.plugin.deactivate') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      icon
                      :color="item.update === 'available' ? 'error' : undefined"
                      :disabled="item.update !== 'available'"
                      @click="$emit('action-button-update', item)"
                    >
                      <v-icon>$update</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.plugin.update') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      icon
                      @click="$emit('action-button-delete', item)"
                    >
                      <v-icon>$thrash</v-icon>
                    </v-btn
                    >
                  </template>
                  <span>{{ $t('button.plugin.delete') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:footer>
              <div>
                <data-iterator-footer
                  :page="page"
                  :numberOfPages="numberOfPages"
                  :possibleItemsPerPage="possibleItemsPerPage"
                  :listStyle="listStyle"
                  :itemsPerPage="itemsPerPage"
                  :itemsTotal="itemsTotal"
                  v-on="$listeners"
                  :perPageLabel="$t('table.footer.plugin')"
                >
                </data-iterator-footer>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../mixins/CustomTableMixin";

import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";
import StatusLabel from "../StatusLabel.vue";
import TableCustomSort from "../dataIterator/TableCustomSort.vue";
import Checkbox from "../buttons/Checkbox.vue";

import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import PluginTableItemsSkeleton from "./PluginTableItemsSkeleton.vue";
import PluginTableSortSkeleton from "./PluginTableSortSkeleton.vue";

export default {
	components: {
		DataIteratorFooter,
		StatusLabel,
		TableCustomSort,
		Checkbox,
		DataIteratorNoResultsContainer,
		PluginTableItemsSkeleton,
		PluginTableSortSkeleton,
	},
	mixins: [DataIteratorMixin, CustomTableMixin],
	props: {
		headers: Array,
		itemsTotal: Number,
		itemsLoading: Boolean,
		searchTerm: String,
	},
	methods: {
		clearFilters: function () {
			this.searchTermComputed = "";
		},
		getDescription(item) {
			let currentLength = 100;

			for (let i = currentLength; i > 0; i--) {
				if (item.charAt(i - 1) === " ") {
					let result = item.slice(0, i - 1);
					return result + " ";
				}
			}
		},
		getPluginUrl: function (item) {
			return item.toLowerCase().replaceAll(" ", "-");
		},
	},
	computed: {
		searchTermComputed: {
			get: function () {
				return this.searchTerm;
			},
			set: function (value) {
				this.$emit("update:searchTerm", value);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.v-card.data-table {
  border-radius: 8px !important;
}

.item-bordered {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: -24px;
    top: -9px;
    height: calc(100% + 18px);
    width: 4px;
    background-color: #dedef1;
  }

  &.item-bordered--red {
    &::after {
      background-color: #E71D1D !important;
    }
  }

  &.item-bordered--green {
    &::after {
      background-color: #07C07E !important;
    }
  }
}

.v-btn {
  margin-left: 10px;
}

.text-truncate {
  text-overflow: ellipsis " ";
}

.v-data-table.custom-data-table::v-deep {
  .v-data-table__wrapper > table > tbody > tr > td {
    height: 64px;
  }
}
.v-application--is-rtl{

.item-bordered::v-deep{
        &::after {
            right: -24px;
            left: unset;
        }
    }
}
</style>
