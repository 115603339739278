<template>
  <tbody class="item">
    <tr v-for="item in rowsNumber" :key="item">
      <td></td>
      <td>
        <div class="d-flex flex-column">
          <v-skeleton-loader type="text" style="width: 50%" />
          <v-skeleton-loader type="text" />
        </div>
      </td>

      <td v-for="itemCell in 5" :key="item + itemCell" class="pl-0">
        <v-skeleton-loader type="text" />
      </td>
      <td style="width: 40px">
        <v-skeleton-loader type="button" />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    rowsNumber: {
      type: Number,
      default: 8,
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  padding: 0px 8px;
  position: relative;
  &:after {
    content: "";
    border-bottom: 1px solid #E5E5F0;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
}
tr td {
  border-bottom: transparent;

  &:first-child {
    display: none;
  }
  &:nth-child(2),
  &:nth-child(4) {
    .v-skeleton-loader {
      width: 175px;
    }
  }
}

.v-skeleton-loader::v-deep {
  .v-skeleton-loader__button {
    width: 36px;
    height: 36px;
  }
}
</style>
