<template>
  <thead class="item sort">
    <th></th>
    <th v-for="item in 3" :key="item">
      <v-skeleton-loader type="text" />
    </th>
    <th></th>
  </thead>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
thead {
  padding: 0px 8px;
  th {
    border-bottom: 1px solid map-get($gray, lighten2);

    height: 52px;
    &:first-child {
      display: none;
    }
    &:nth-child(3) {
      width: 176px;
    }

    &:nth-child(4) {
      width: 50%;
    }

    padding: 6px 16px;
    .v-skeleton-loader {
      max-width: 62px;
    }
  }
}
</style>