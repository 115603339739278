<template>
  <div
    :class="[
      checked ? 'data-iterator-card--selected' : '',
      item.status === 'active' ? 'data-iterator-card--green' : '',
      item.update === 'available' && item.status === 'active'
        ? 'data-iterator-card--red'
        : '',
    ]"
    class="data-iterator-card"
  >
    <div class="data-iterator-card__checkbox-container">
      <checkbox :checked="checkedComputed" @change="handleCheckbox" />
    </div>
    <v-card elevation="4">
      <v-card-title
        class="d-flex justify-space-between align-center flex-nowrap"
      >
        <h5 class="item-title" v-html="item.title"></h5>
        <v-tooltip
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
          offset-overflow
          nudge-bottom="4px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="ml-auto" v-on="on" v-bind="attrs">
              <v-icon
                v-if="item.update === 'available'"
                color="error"
                size="16"
              >
                $alertwarning
              </v-icon>
              <v-icon
                v-else
                size="16"
                :color="item.status === 'active' ? 'primary' : 'gray darken-1'"
              >
                $checkcircle
              </v-icon>
            </div>
          </template>
          <span class="gray--text text--darken-2">
            <span v-if="item.update ==='available'">{{ $t('tooltip.instance.plugin.update.available') }}</span>
            <span v-else>{{ $t('tooltip.instance.plugin.update.current') }}</span>
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="p-3">
        <span
          v-html="getDescription(item.description)"
          class="truncate"
          ref="text"
        >
        </span>
        <a
          v-if="hasReadMoreButton"
          @click="$emit('action-button-read-more', item)"
          class="truncate-text"
        >
          <span class="gray--text text--darken-2 font-weight-regular"
            >...&nbsp;
          </span>
          {{ $t('button.readMore') }}
        </a>
      </v-card-text>
      <v-card-actions class="pt-2 px-4">
        <div
          v-if="item.hasInstallInProgress()"
          class="d-flex w-100 justify-space-between"
        >
          <base-icon-button icon="$update" :text="$t('button.installing')" loading />
        </div>
        <div
          v-else-if="item.hasUpdateInProgress()"
          class="d-flex w-100 justify-space-between"
        >
          <base-icon-button icon="$update" :text="$t('button.updating')" loading />
        </div>
        <div v-else class="d-flex w-100 justify-space-between">
          <div class="d-flex">
            <v-tooltip
              open-delay="150"
              bottom
              transition="custom-tooltip"
              v-if="item.status === 'inactive'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <base-icon-button
                    icon="$checkcircle"
                    :text="$t('button.activate')"
                    @click.native="$emit('action-button-activate', item)"
                  />
                </div>
              </template>
              <span class="base--text">{{ $t('button.plugin.activate') }}</span>
            </v-tooltip>

            <v-tooltip
              open-delay="150"
              bottom
              transition="custom-tooltip"
              v-else
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <base-icon-button
                    icon="$xcircle"
                    :text="$t('button.disable')"
                    @click.native="$emit('action-button-deactivate', item)"
                  />
                </div>
              </template>
              <span class="base--text">{{ $t('button.plugin.deactivate') }}</span>
            </v-tooltip>

            <v-tooltip
              open-delay="150"
              bottom
              transition="custom-tooltip"
              v-if="item.update === 'available'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <base-icon-button
                    color="error"
                    icon="$update"
                    :text="$t('button.update')"
                    @click.native="$emit('action-button-update', item)"
                  />
                </div>
              </template>
              <span class="base--text">{{ $t('button.plugin.update') }}</span>
            </v-tooltip>
          </div>
          <v-tooltip open-delay="150" bottom transition="custom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <base-icon-button
                  icon="$thrash"
                  :text="$t('button.remove')"
                  @click.native="$emit('action-button-delete', item)"
                />
              </div>
            </template>
            <span class="base--text">{{ $t('button.plugin.delete') }}</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import BaseIconButton from "../buttons/BaseIconButton.vue";
import Checkbox from "../buttons/Checkbox.vue";

import DataIteratorItemMixin from "../../mixins/DataIteratorItemMixin.js";

export default {
  components: {
    BaseIconButton,
    Checkbox,
  },
  mixins: [DataIteratorItemMixin],
  data() {
    return {
      hasReadMoreButton: true,
    };
  },
  methods: {
    getDescription() {
      let item = this.item.description;
      let currentLength = 100;
      if (item.length <= currentLength) {
        this.hasReadMoreButton = false;
        return item;
      }

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
    getPluginUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "-");
    },
  },
};
</script>

<style lang="scss" scoped>
.data-iterator-card {
  .v-card {
    border: 0px solid;
    border-top: 6px solid;
    border-color: var(--v-gray-lighten3);
    border-radius: 8px;

    .v-card__text {
      position: relative;
      padding-bottom: 24px;
      margin-bottom: -24px;
    }

    .v-card__title {
      padding-top: 18px;
    }
  }

  .data-iterator-card__checkbox-container {
    background: white;
    left: -34px;
    top: 26px;
    width: 42px !important;
    height: 42px !important;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: opacity 0.24s ease;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
      0px 8px 24px 6px rgba(0, 0, 0, 0.02), 0px 8px 32px rgba(0, 0, 0, 0.08);

    .checkbox {
      margin: 0px !important;
    }

    &::before {
      background: white;
      width: 24px;
      height: 72px;
      top: -6px;
      right: -17px;
    }
  }

  &:hover {
    .data-iterator-card__checkbox-container {
    }
  }

  &--selected {
    .v-card {
      outline: none !important;
    }
  }

  &--green {
    .v-card {
      border-top-color: var(--v-primary-base);
    }
  }

  &--red {
    .v-card {
      border-top-color: var(--v-error-base);
    }

    .data-iterator-card__checkbox-container {
      border-color: var(--v-error-base);
    }
  }
  &.data-iterator-card--selected {
    .v-card {
      &:before {
        box-shadow: none !important;
      }
    }
  }
}

.truncate {
  // margin-bottom: auto;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // padding-bottom: 0px;
}

.truncate-text {
  font-weight: $font-weight-bold;
  color: var(--v-info-base);
  display: inline;
  justify-content: flex-end;
  margin-left: -4px;
}
.v-application--is-rtl{
    .data-iterator-card{
        .data-iterator-card__checkbox-container{
            right: -32px;
            &::before{
                right: 32px;
            }
        }
    }
}
</style>
