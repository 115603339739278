<template>
  <div
    class="data-iterator-card"
    :class="item.featured ? 'data-iterator-card--featured' : ''"
  >
    <v-card elevation="4" class="item">
      <div v-if="item.featured">
        <div class="featured-label">
          <span class="featured-label__text">{{ $t('general.featured') }}</span>
        </div>
      </div>
      <v-card-title
        class="d-flex justify-space-between align-start"
        style="flex-wrap: nowrap"
      >
        <h5 v-html="item.name"></h5>
        <v-tooltip bottom nudge-bottom="8px" v-if="item.featured">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              class="ml-2 primary-on-hover"
              size="16"
              color="gray lighten-1"
            >
              $alertcircle
            </v-icon>
          </template>
          <span class="text-base">{{ $t('message.status.plugin.special') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="p-3">
        <span
          v-html="getDescription(item.short_description || item.description)"
          class="truncate"
          ref="text"
        />
        <a
          v-if="hasReadMoreButton"
          class="truncate-text"
          @click="$emit('action-button-read-more', item)"
        >
          <span class="gray--text text--darken-2 font-weight-regular"
            >...&nbsp;</span
          >
          {{ $t('button.readMore') }}
        </a>
      </v-card-text>
      <v-card-actions class="pt-0">
        <div class="d-flex align-center">
          <div class="d-flex mr-3 align-center" v-if="item.rating">
            <v-icon size="20" class="mr-1" :percent="item.rating / 100"
              >$starIcon</v-icon
            >
            <b class="p-4">{{ Math.ceil((item.rating / 10) * 5) / 10 }}</b>
          </div>

          <div class="d-flex align-center" v-if="item.active_installs">
            <v-icon size="20" elevation="0" class="mr-1" color="gray lighten-1"
              >$installIcon</v-icon
            >
            <b class="p-4">{{ item.active_installs }}+</b>
          </div>
        </div>
        <v-tooltip
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              elevation="0"
              style="min-width: 38px; max-width: 38px"
              class="ml-auto"
              color="primary"
              @click="$emit('action-button-install', item)"
              small
            >
              <v-icon
                v-if="
                  !item.hasInstallInProgress() && !item.hasUpdateInProgress()
                "
                size="18"
                class="mr-0"
                color="white"
              >
                $installIcon
              </v-icon>

              <loader
                v-else
                color="white"
                size="24"
                class="mr-0"
                :noMargins="true"
              />
            </v-btn>
          </template>

          <span class="base--text">{{ $t('button.plugin.install') }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    loading: Boolean,
    item: Object,
    itemIsFeatured: Boolean,
  },
  data() {
    return {
      hasReadMoreButton: true,
    };
  },
  methods: {
    getDescription(item) {
      let currentLength = 100;

      if (item.length <= currentLength) {
        this.hasReadMoreButton = false;
        return item;
      }

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
    getPluginUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "-");
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  // margin-bottom: auto;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // padding-bottom: 0px;
}

.data-iterator-card::v-deep {
  .v-card {
    border: 0px solid;
    border-top: 6px solid;
    border-color: transparent;
    border-radius: 8px;
    &__title {
      padding-top: 18px;
    }
  }

  &.data-iterator-card--featured {
    .v-card {
      border-color: #fadd77;
      .primary-on-hover:hover {
        color: var(--v-primary-base) !important;
        transition: color 0.24s ease;

        .v-icon svg,
        svg {
          color: var(--v-primary-base) !important;
          caret-color: var(--v-primary-base) !important;
        }
      }
    }
    .featured-label {
      position: absolute;
      top: -14px;
      right: -8px;
      overflow: hidden;
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        background: #f2ce98;
        z-index: 0;
      }
      &::before {
        top: 0;
        left: 0;
      }
      &::after {
        bottom: 0;
        right: 0;
      }
      .featured-label__text {
        transform: rotate(45deg);
        color: #ec7b13;
        display: block;
        position: relative;
        font-size: $font-size-xxs;
        line-height: $line-height-xxs;
        font-weight: $font-weight-bold;
        bottom: 8px;
        left: 8px;
        text-align: center;
        z-index: 10;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -24px;
          width: 120px;
          height: 18px;
          background: #fff2d3;
          z-index: -1;
        }
      }
    }
  }
}

.v-card__text {
  padding-bottom: 24px;
}

.truncate-text {
  font-weight: $font-weight-bold;
  color: var(--v-info-base);
  display: inline-flex;
  justify-content: flex-end;
  margin-left: -4px;
}
</style>
